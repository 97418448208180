import React from 'react';
import { useState } from 'react';
const init = [];
export default function Select({ name, label, items = init, handleChange, value, errMsg }) {
  const [type, setType] = useState('')
  const handleOnChange = (e) => {
    const value = e.target.value;
    console.log(value)
    setType(value)
    handleChange(value, name);
  };

  return (
    <div className="budget-area">
      <p className="mb-3 pt-0">
        <i className="fas fa-user"></i> {label}
      </p>
      <select
        name="budget"
        value={value}
        className={`form-control ${errMsg ? 'is-invalid error mb-1' : ''}`}
        aria-invalid={!!errMsg}
        onChange={handleOnChange}
        onBlur={handleOnChange}>
        <option key={100000} value="">
          Pilih Responden
        </option>
        {items.map((el, i) => (
          <option key={i} value={el.value}>
            {el.text}
          </option>
        ))}
      </select>
      {errMsg && (
        <label id={`${name}-error`} className="error" htmlFor={name}>
          {errMsg}
        </label>
      )}
    </div>
  );
}
