/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventList from '../components/event/EventList';
import API from '../config/API';
import callServerV2 from '../helpers/callServerV2';

export default function Home() {
  const dispatch = useDispatch();

  const { config } = useSelector((state) => state.configReducer);

  useEffect(() => {
    initConfig();
  }, []);

  const initConfig = () => {
    const url = API.URL_SURVEY_CONFIG;
    dispatch(
      callServerV2({
        url,
        prefix: 'CFG',
        type: 'SET_CONFIGS',
        stage: 'loadConfigs',
      }),
    );
  };

  return (
    <div className="wrapper">
      <div className="steps-area steps-area-fixed">
        <div className="image-holder">
          <img src={`${config.banner}`} alt="" />
        </div>
      </div>
      <div className="multisteps-form__form">
        <div className="form-area position-relative">
          <EventList />
        </div>
      </div>
    </div>
  );
}
