import { combineReducers } from "redux";
import eventReducer from "./eventReducer";
import responseReducer from "./responseReducer";
import configReducer from './configReducer';

// Create New Reducer If need then import and combine in this metho
export default combineReducers({
  eventReducer,
  responseReducer,
  configReducer,
});
