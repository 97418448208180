/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import API from '../../config/API';
import { axios } from '../../config/axios';
import SweatAlert from '../../helpers/SweatAlert';
import Validation from '../../helpers/Validation';
import { actionSetResponse } from '../../store/actions/responseAction';
import ButtonNav from './element/ButtonNav';
import StepProgress from './element/StepProgress';
import TextInput from './element/TextInput';
import Select from './option/Select';

const init = {
  full_name: '',
  email: '',
  phone: '',
  profession: '',
  company: '',
  cabang: ''
};

export default function Respondent(props) {
  // const { event } = props;
  const [errMsg, setErrMsg] = useState({ ...init });

  const { event } = useSelector((state) => state.eventReducer);
  const response = useSelector((state) => state.responseReducer);
  const dispatch = useDispatch();

  if (!event) return <h1>No Selected Event</h1>;

  const checkResponden = async () => {
    return new Promise((resolve) => {
      (async () => {
        try {
          const query = `email=${response.email}&phone=${response.phone}&event=${event._id}&uuid=${response.uuid}`;
          const url = `${API.URL_RESPONSE_CHECK}?${query}`;
          const { data } = await axios.get(url);
          resolve(data.response);
        } catch (error) {
          console.error(error);
          resolve({ isExist: false, responseId: null });
        }
      })();
    });
  };

  const validation = (field, value) => {
    switch (field) {
      case 'type': {
        if (!value) return 'Responden wajib di isi';
        return '';
      }
      case 'full_name': {
        if (!value) return 'Nama wajib di isi';
        return '';
      }
      case 'email': {
        if (!value) return 'Email wajib di isi';
        if (!Validation.isEmail(value)) return 'Email tidak valid';
        return '';
      }
      case 'phone': {
        if (!value) return 'Telpon wajib di isi';
        if (Number.isNaN(+value)) return 'Telpon Tidak Valid';
        if (value.length < 9 || value.length > 13) return 'Nomor Telpon 9 - 13 Digit Angka';
        return '';
      }
      case 'gender': {
        if (!['laki-laki', 'perempuan'].includes(value)) return 'Jenis Kelamin di wajib di isi';
        return '';
      }
      case 'profession': {
        if (!value) return 'Pekerjaan / Jabatan wajib di isi';
        return '';
      }
      case 'company': {
        if (!value) return 'Perusahaan wajib di isi';
        return '';
      }
      case 'cabang': {
        if (!value) return 'Cabang wajib di isi *jika tidak ada bisa di isi (-) ';
        return '';
      }
      default:
        return '';
    }
  };

  const validate = async () => {
    let isValid = true;
    const newErrMsg = { ...errMsg };
    const k1 = ['type', 'full_name', 'email', 'phone','company', 'cabang'];
    const keys = [...k1, 'gender', 'profession'];
    keys.forEach((el) => {
      const msg = validation(el, response[el]);
      if (msg) isValid = false;
      newErrMsg[el] = msg;
    });
    setErrMsg(newErrMsg);
    const result = await checkResponden();
    if (result.isExist) {
      SweatAlert.warning(result.message);
      isValid = false;
    }
    // if (!isValid) SweatAlert.warning('Mohon Lengkapi Field yang di butuhkan');
    return isValid;
  };

  const handleChange = (value, name) => {
    const newRes = { ...response, [name]: value, eventId: event._id };
    const msg = validation(name, value);
    setErrMsg({ ...errMsg, [name]: msg });
    dispatch(actionSetResponse(newRes));
  };

  const getItems = () => {
    const items = event.typeRespondents.map((el) => {
      return { value: el, text: el };
    });
    return items;
  };

  return (
    <div className="multisteps-form__panel js-active" data-animation="slideHorz">
      {/* div 2 */}
      <div className="wizard-forms">
        <div className="pb-100 clearfix">
          <form action="#" className="wizard__form">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                {/* <h1 className="mb-3">Judul Survey</h1> */}

                <StepProgress />

                <h2>{event.name}</h2>
                <p>{event.description}</p>
                <div className="form-inner-area">
                  <Select
                    name="type"
                    label="Responden"
                    errMsg={errMsg.type}
                    value={response.type}
                    handleChange={handleChange}
                    items={getItems()}
                  />
                  <TextInput
                    type="text"
                    name="full_name"
                    value={response.full_name}
                    label="Nama Lengkap"
                    handleChange={handleChange}
                    errMsg={errMsg.full_name}
                  />
                  <TextInput
                    type="email"
                    name="email"
                    value={response.email}
                    label="Alamat Email (Jawaban kamu akan di kirim ke email)"
                    handleChange={handleChange}
                    errMsg={errMsg.email}
                  />
                  <TextInput
                    type="number"
                    name="phone"
                    value={response.phone}
                    label="Nomor Telpon"
                    handleChange={handleChange}
                    errMsg={errMsg.phone}
                  />
                  <TextInput
                    type="text"
                    name="profession"
                    value={response.profession}
                    label="Pekerjaan / Jabatan"
                    handleChange={handleChange}
                    errMsg={errMsg.profession}
                  />
                  <TextInput
                    type="text"
                    name="company"
                    value={response.company}
                    label="Nama Perusahaan"
                    handleChange={handleChange}
                    errMsg={errMsg.company}
                  />
                   <TextInput
                    type="text"
                    name="cabang"
                    value={response.cabang}
                    label="Nama Cabang"
                    handleChange={handleChange}
                    errMsg={errMsg.cabang}
                  />
                </div>
                <div className="gender-selection">
                  <h3 className="pt-0">Jenis Kelamin:</h3>
                  <div className="services-select-option opti-list">
                    {errMsg.gender && (
                      <span id="gender-error" className="error text-danger" htmlFor="gender">
                        {errMsg.gender}
                      </span>
                    )}
                    <ul>
                      <li
                        onClick={() => handleChange('laki-laki', 'gender')}
                        className={`bg-white ${response.gender === 'laki-laki' ? 'active' : ''}`}>
                        <label>
                          Laki - Laki{' '}
                          <input
                            onChange={() => {}}
                            type="radio"
                            name="gender"
                            value="laki-laki"
                            checked={response.gender === 'laki-laki'}
                            className="form-control"
                            required
                          />
                        </label>
                      </li>
                      <li
                        onClick={() => handleChange('perempuan', 'gender')}
                        className={`bg-white  ${
                          response.gender === 'perempuan' ? 'active' : 'sdsds'
                        }`}>
                        <label>
                          Perempuan{' '}
                          <input
                            onChange={() => {}}
                            type="radio"
                            name="gender"
                            value="perempuan"
                            checked={response.gender === 'perempuan'}
                            className="form-control"
                            required
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <ButtonNav validate={validate} {...props} btnId="respondent" />
      </div>
    </div>
  );
}
