const INIT = {
  full_name: '',
  email: '',
  phone: '',
  gender: '',
  profession: '',
  company: "",
  eventId: '',
  uuid: '',
  type: '',
  questions: [],
};

/**
  Question: {
    quetionId: '',
    answer: '',
    note: '',
  }
 */

// This Reducer User, save all data user in here as store.
export default function reducer(state = { ...INIT }, action) {
  switch (action.type) {
    case "RSP_SET_REPONSE": {
      return { ...state, ...action.payload };
    }
    case "RSP_CLEAR_RESPONSE": {
      return { ...state, ...INIT, questions: [] };
    }
    default:
      return state;
  }
}
