const $ = window.$;

class Scriptjs {
  constructor(DOMstrings) {
    this.DOMstrings = DOMstrings;
  }

  //remove class from a set of items
  removeClasses(elemSet, className) {
    elemSet.forEach((elem) => {
      elem.classList.remove(className);
    });
  }

  //return exect parent node of the element
  findParent(elem, parentClass) {
    let currentNode = elem;

    while (!currentNode.classList.contains(parentClass)) {
      currentNode = currentNode.parentNode;
    }

    return currentNode;
  }

  //get active button step number
  getActiveStep = (elem) => {
    const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);
    return Array.from(stepsBtns).indexOf(elem);
  };

  //set all steps before clicked (and clicked too) to active
  setActiveStep(activeStepNum) {
    //remove active state from all the state
    const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);
    this.removeClasses(stepsBtns, 'js-active');
    this.removeClasses(stepsBtns, 'current');

    //set picked items to active
    stepsBtns.forEach((elem, index) => {
      if (index <= activeStepNum) {
        elem.classList.add('js-active');
        $(elem).addClass(index);
      }

      if (index === activeStepNum) {
        elem.classList.add('current');
      }
    });
  }

  //get active panel
  getActivePanel() {
    let activePanel;
    const stepFormPanels = document.querySelectorAll('.multisteps-form__panel');
    stepFormPanels.forEach((elem) => {
      if (elem.classList.contains('js-active')) {
        activePanel = elem;
      }
    });

    return activePanel;
  }

  //open active panel (and close unactive panels)
  setActivePanel(activePanelNum) {
    const stepFormPanels = document.querySelectorAll('.multisteps-form__panel');
    if (activePanelNum >= stepFormPanels.length) return false;
    const animation = $(stepFormPanels, 'js-active').attr('data-animation');

    //remove active class from all the panels
    this.removeClasses(stepFormPanels, 'js-active');
    this.removeClasses(stepFormPanels, animation);
    this.removeClasses(stepFormPanels, 'animate__animated');

    //show active panel
    stepFormPanels.forEach((elem, index) => {
      if (index === activePanelNum) {
        elem.classList.add('js-active');
        // stepFormPanels
        elem.classList.add('animate__animated', animation);

        setTimeout(() => {
          this.removeClasses(stepFormPanels, 'animate__animated', animation);
        }, 1200);

        this.setFormHeight(elem);
      }
    });
  }

  //set form height equal to current panel height
  formHeight(activePanel) {
    if (activePanel) {
      const activePanelHeight = activePanel.offsetHeight;
      const stepsForm = document.querySelector('.multisteps-form__form');
      stepsForm.style.height = `${activePanelHeight}px`;
    }
  }

  setFormHeight = () => {
    const activePanel = this.getActivePanel();
    this.formHeight(activePanel);
  };
}

export default Scriptjs;
