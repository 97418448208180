import React from 'react';

export default function Checkbox({ opt, handleChange, isChecked }) {
  return (
    <li
      onClick={() => handleChange(opt._id)}
      className={`bg-white w-100 my-2 ${isChecked ? 'active' : ''}`}>
      <input
        type="checkbox"
        name={opt._id}
        value={opt._id}
        className="custome-checkbox form-control"
        checked={isChecked}
      />
      {opt.text}
    </li>
  );
}
