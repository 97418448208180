import Axios from 'axios';
// import LocalStorage from '../libs/LocalStorage';

export const defaultHeaders = (formData = false) => {
  const temp = {
    access_token: '',
  };
  if (formData) {
    temp['Content-Type'] = 'multipart/form-data';
  }
  return temp;
};

export const axios = Axios.create({
  // baseURL: 'https://dev.bspnet.id/api/v1',
  baseURL: 'https://www.bspnet.id/api/v1',
  // baseURL: 'https://webportal.binasentra.co.id/api/v1',
  // baseURL: 'http://localhost:3001/api/v1',
  // baseURL: 'http://192.168.15.52:3001/api/v1',
  timeout: 180000, // 3 Menit,
  // withCredentials: true,
});
