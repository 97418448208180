export default function errorHandler(error) {
  if (error) {
    if (error.response) {
      const data = error.response.data;
      if (data) {
        // console.log('error', error.response);
        if (data.response) {
          return data.response ? data.response : 'Something Error';
        }
      }
    }
    console.error('Error: ', error.message);
    return error.message ? error.message : 'Something error';
  }
  return '';
}
