import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionClearEvent } from '../store/actions/eventsAction';
import { actionClearResponse } from '../store/actions/responseAction';

export default function Thankyou() {
  const dispatch = useDispatch();

  const { event } = useSelector((state) => state.eventReducer);
  const { config } = useSelector((state) => state.configReducer);

  const handleHome = () => {
    dispatch(actionClearEvent(event));
    dispatch(actionClearResponse());
  };

  return (
    <section id="thank-you" className="thank-you-section">
      <div className="container">
        <div className="thank-you-wrapper position-relative thank-wrapper-style-one">
          {/* <div className="thank-you-close text-center">x</div> */}
          <div className="thank-txt text-center">
            <div className="thank-icon">
              <img src={`${config.companyLogo}`} alt="company-logo" />
            </div>
            <h1>{config.thankyouTitle}</h1>
            <p>{config.thankyouMessage}</p>
            <Link className="d-block text-center text-uppercase" onClick={handleHome} to="#">
              HOME
            </Link>
          </div>
          {/* <div className="th-bottom-vector position-absolute">
            <img src={`${process.env.PUBLIC_URL}/assets/img/thankyou/tv1.png`} alt="icon" />
          </div> */}
        </div>
      </div>
    </section>
  );
}
