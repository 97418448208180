/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../config/API';
import { axios } from '../../config/axios';

export default function EventList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    loadEvents();
  }, []);

  const { config } = useSelector((state) => state.configReducer);

  const loadEvents = async () => {
    try {
      const url = API.URL_SURVEY_LIST;
      const { data } = await axios.get(url);
      const { /* currentPage, total, totalPages, */ rows } = data.response;
      setEvents(rows);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="multisteps-form__panel js-active" data-animation="slideHorz">
      <div className="wizard-forms">
        <div className="inner pb-100 clearfix">
          <div className="form-content pera-content">
            <div className="step-inner-content">
              {/* <span className="step-no">Step 1</span> */}
              {/* <StepProgress /> */}
              <h2>{config.homeTitle}</h2>
              {config.homeSubtitle && <p>{config.homeSubtitle}</p>}
              <div className="step-box">
                <form action="#" className="wizard__form" id="form-event-list">
                  <div className="row">
                    {events.length < 1 && (
                      <div className="col-md-12">
                        <h4 className="mb-4 text-center">Kami sedang menyiapkan event atau survey X</h4>

                        <img
                          src={`${process.env.PUBLIC_URL}/assets/img/prepare_event.svg`}
                          alt="icon"
                        />
                      </div>
                    )}
                    {events.map((el, i) => (
                      <div key={i} className="col-md-4 d-flex align-items-stretch">
                        <label className="step-box-content bg-white text-center relative-position w-100">
                          <span className="step-box-icon">
                            <img src={el.icon} alt="icon" className="img__thumbnail" />
                          </span>
                          <span className="step-box-text">{el.name}</span>
                          <Link to={el.slug}>
                            <span className="service-check-option">
                              <span>
                                <input
                                  type="radio"
                                  name="service_name"
                                  id={el._id}
                                  defaultValue="Corporate Services"
                                  className="form-control required"
                                  required
                                />
                              </span>
                            </span>
                          </Link>
                        </label>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
