import React from 'react';
import { useSelector } from 'react-redux';

export default function StepProgress() {
  const { totalSteps, currentStep } = useSelector((state) => state.eventReducer);
  const tempCurr = currentStep + 1;
  const progres = (tempCurr / totalSteps) * 100;
  return (
    <>
      <span className="step-no bottom-line">Step {tempCurr}</span>
      <div className="step-progress float-right">
        <span>
          {tempCurr} of {totalSteps} completed
        </span>
        <div className="step-progress-bar">
          <div className="progress">
            <div className="progress-bar" style={{ width: `${progres}%` }}></div>
          </div>
        </div>
      </div>
    </>
  );
}
