/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Checkbox from '../option/Checkbox';

export default function ComboParent({ options, questId, handleChange, response }) {
  return (
    <div className="budget-area">
      {/* <p>Optimization and Accessibility</p> */}
      <div className="opti-list">
        <ul className="">
          {options.map((el, i) => (
            <Checkbox
              key={i}
              handleChange={(optId) => handleChange(optId, optId)}
              questId={questId}
              opt={el}
              isChecked={response.answer.includes(el._id)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
