import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetResponse } from '../../store/actions/responseAction';
import ButtonNav from './element/ButtonNav';
import ComboParent from './element/ComboParent';
import StepProgress from './element/StepProgress';
import Radio from './option/Radio';
import TextArea from './option/TextArea';

export default function Question(props) {
  const { item } = props;

  const [errMsg, setErrMsg] = useState('');
  const { event } = useSelector((state) => state.eventReducer);
  const response = useSelector((state) => state.responseReducer);

  const dispatch = useDispatch();

  const validate = (value) => {
    let msg = '';
    const newRes = { ...response };
    const index = newRes.questions.map((el) => el.questionId).indexOf(item._id);
    if (index < 0) msg = 'Harap lengkapi jawaban kamu';
    else if (newRes.questions[index].answer.length < 1) msg = 'Harap lengkapi jawaban kamu';
    if (value) msg = '';
    setErrMsg(msg);
    return msg.length < 1;
  };

  const handleChange = (value, optionId) => {
    const newRes = { ...response };
    validate(value);
    // Check Answer
    const index = newRes.questions.map((el) => el.questionId).indexOf(item._id);
    if (index > -1) {
      // const oldAnswer = newRes.questions[index].answer.indexOf(optionId);
      switch (item.type) {
        case 'radio':
          newRes.questions[index].answer = [optionId];
          break;
        case 'combo': {
          const temps = newRes.questions[index].answer;
          const i = temps.indexOf(optionId);
          if (i > -1) newRes.questions[index].answer.splice(i, 1);
          else newRes.questions[index].answer.push(optionId);
          break;
        }
        default:
          break;
      }
    } else {
      if (optionId) {
        newRes.questions.push({
          questionId: item._id,
          answer: [optionId],
        });
      }
    }
    dispatch(actionSetResponse(newRes));
  };

  const getOption = (item, opt) => {
    const found = response.questions.find((el) => el.questionId === item._id);
    const value = found ? found.answer : [];
    switch (item.type) {
      case 'radio':
        return (
          <Radio
            key={opt._id}
            questId={item._id}
            value={value}
            opt={opt}
            handleChange={handleChange}
          />
        );
      default:
        return (
          <Radio
            key={opt._id}
            questId={item._id}
            value={value}
            opt={opt}
            handleChange={handleChange}
          />
        );
    }
  };

  const getValue = () => {
    const found = response.questions.find((el) => el.questionId === item._id);
    const def = {
      questionId: '',
      answer: [],
      note: null,
    };
    return found ? found : def;
  };

  return (
    <div className="multisteps-form__panel" data-animation="slideHorz">
      <div className="wizard-forms">
        <div className="inner pb-100 clearfix">
          <form action="#" className="wizard__form">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <h2 className="mb-3">{event && event.name}</h2>
                <StepProgress />
                <h3>{item.title}</h3>
                {errMsg && (
                  <label id="gender-error" className="error text-danger mb-3" htmlFor="gender">
                    {errMsg}
                  </label>
                )}
                <p className="mt-1">{item.description}</p>
                {item.type === 'radio' && (
                  <div className="services-select-option" id={item._id}>
                    <ul>{item.options.map((el, i) => getOption(item, el))}</ul>
                  </div>
                )}
                {item.type === 'combo' && (
                  <ComboParent
                    options={item.options}
                    handleChange={handleChange}
                    questId={item._id}
                    response={getValue()}
                  />
                )}
                {item.useNote && <TextArea questId={item._id} response={getValue().note} />}
              </div>
            </div>
          </form>
        </div>

        <ButtonNav {...props} validate={validate} btnId={`question-${item._id}`} />
      </div>
    </div>
  );
}
