const INIT = {
  config: {},
};

// This Reducer User, save all data user in here as store.
export default function reducer(state = { ...INIT }, action) {
  switch (action.type) {
    case 'CFG_SET_CONFIGS': {
      const { response } = action.payload;
      const temps = [];
      response.forEach((el) => {
        temps[el.slug] = el.value;
      });
      return { ...state, config: temps };
    }
    default:
      return state;
  }
}
