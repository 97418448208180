/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/event/element/Loading';
import API from '../config/API';
import { axios } from '../config/axios';
import callServerV2 from '../helpers/callServerV2';
import errorHandler from '../helpers/errorHandler';
import NotFound from './NotFound';
import Review from './Review';

export default function Detail() {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const dispatch = useDispatch();

  const { config } = useSelector((state) => state.configReducer);

  const history = useNavigate();
  const { search } = history.location;
  const Qs = new URLSearchParams(search);
  const id = Qs.get('uniqueid');
  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config) {
      loadResponden();
    }
  }, [config]);

  const initConfig = () => {
    const url = API.URL_SURVEY_CONFIG;
    dispatch(
      callServerV2({
        url,
        prefix: 'CFG',
        type: 'SET_CONFIGS',
        stage: 'loadConfigs',
      }),
    );
  };

  const loadResponden = async () => {
    try {
      setLoading(true);
      const url = `${API.URL_REPONSE_REVIEW}/${id}`;
      const { data } = await axios.get(url);
      setResponse(data.response);
    } catch (error) {
      const msg = errorHandler(error);
      console.error(msg);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !response) return <Loading />;
  if (response) return <Review response={response} />;

  return (
    <div className="wrapper">
      <div className="steps-area steps-area-fixed">
        <div className="image-holder">
          <img src={`${config.banner}`} alt="" />
        </div>
      </div>
      <div className="multisteps-form__form">
        <div className="form-area position-relative" style={{ height: '100vh' }}>
          <NotFound />
        </div>
      </div>
    </div>
  );
}
