// import './App.scss';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

// Pages
import Home from './pages/Home';
import Event from './pages/Event';

//main css
import './assets/css/animate.min.css';
import './assets/scss/style.scss';
import Detail from './pages/Detail';

// const getBasename = (path) => path.substr(0, path.lastIndexOf('/'));

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        
          <Routes>
            <Route path="/review" element={<Detail/>} />
            <Route path="/:slug" element={<Event/>} />
            <Route path="/" element={<Home/>} />
          </Routes>
       
      </PersistGate>
    </Provider>
  );
}

export default App;
