import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import reducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ['vehicleReducer'],
  transforms: [
    encryptTransform({
      secretKey: "nhsdjflkj45345mnzmxdcsdfs",
      onError: function (error) {
        // Handle the error.
        console.error("redux persist", error.message);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

// This is store, to create store and apply middleware (thunk) to execute async await fetch data function.
const isProduction = process.env.NODE_ENV === "production";
const reduxDev = isProduction
  ? null
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = reduxDev || compose;

// const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const persistor = persistStore(store);
export { store, persistor };

// export default store;
