import React from 'react';

export default function Radio({ questId, opt, value, handleChange }) {
  return (
    <li
      className={`bg-white  ${value.includes(opt._id) ? 'active' : ''}`}
      onClick={() => handleChange(opt.value, opt._id)}>
      <label>
        {opt.text}
        <input
          type="radio"
          name={questId}
          id={opt._id}
          value={opt.value}
          className="form-control"
          checked={value.includes(opt._id)}
          onChange={() => {}}
          required
        />
      </label>
    </li>
  );
}
