import React from 'react';
import { useNavigate } from 'react-router-dom';
import QuestionCard from '../components/review/QuestionCard';

export default function Review({ response }) {
  const history = useNavigate();
  if (!response) return null;
  const { questions = [], slug } = response.event;
  const { full_name, email, type, gender, profession } = response.respondent;

  const { search } = history.location;
  const handleClick = () => {
    const link = `/${slug}${search}&edit=true`;
    history.push(link);
  };

  return (
    <section className="my-5 pt-sm-5">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade active show"
                id="v-pills-gen-ques"
                role="tabpanel"
                aria-labelledby="v-pills-gen-ques-tab">
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm waves-effect waves-light px-3 mr-3"
                      onClick={() => handleClick()}>
                      <span>
                        <i className="fas fa-edit" />
                      </span>
                    </button>
                    <div className="flex-1 overflow-hidden">
                      <h5 className="font-size-16 mb-1">{full_name}</h5>
                      <p className="text-truncate mb-0">
                        {gender} | {profession}
                      </p>
                    </div>
                  </div>
                  <div className="font-size-14 ms-auto d-none d-md-block">
                    <p className="text-right mb-0">
                      <strong>{type}</strong>
                    </p>
                    {email}
                  </div>
                </div>
                {questions.map((el, i) => (
                  <QuestionCard key={i} index={i + 1} question={el} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
