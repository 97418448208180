import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="thank-you-wrapper position-relative thank-wrapper-style-one no-shadow px-3">
      <div className="thank-txt text-center">
        <div className="thank-icon">
          <img src={`${process.env.PUBLIC_URL}/assets/img/not_found.svg`} alt="not-found" />
        </div>
        <h1>Terima kasih atas partisipasinya</h1>
        <p>
          Namun sepertinya kami tidak menemukan kuesioner yang kamu cari. Bisa jadi karena kuesioner
          sudah berakhir.
        </p>
        <Link className="d-block text-center text-uppercase" to="">
          Home
        </Link>
      </div>
    </div>
  );
}
