const INIT = {
  currentPage: 1,
  respondent: null,
  total: 0,
  totalPages: 1,
  events: [],
  event: null, // { questions: [] },
  totalSteps: 2,
  currentStep: 0,
  submited: false,
};

// This Reducer User, save all data user in here as store.
export default function reducer(state = INIT, action) {
  switch (action.type) {
    case 'EVT_SET_EVENTS': {
      const { currentPage, total, totalPages, rows } = action.payload.response;
      const r1 = { currentPage, total, totalPages, events: rows };
      const r2 = { error: null, stage: action.stage };
      return { ...state, ...r1, ...r2 };
    }
    case 'EVT_SET_EVENT': {
      const event = action.payload.response || action.payload;
      const respondent = action.payload.respondent;
      return { ...state, event, stage: action.stage, respondent };
    }
    case 'EVT_SET_CURR_STEP': {
      return { ...state, currentStep: action.payload };
    }
    case 'EVT_SET_TOTAL_STEP': {
      return { ...state, totalSteps: action.payload };
    }
    case 'EVT_SET_SUBMITED': {
      return { ...state, submited: action.payload };
    }
    case 'EVT_CLEAR_STAGE': {
      return { ...state, error: null, stage: null };
    }
    case 'EVT_CLEAR_EVENT': {
      const r1 = { currentStep: 0, submited: false };
      return { ...state, ...r1, event: action.payload };
    }
    case 'EVT_LOADING':
      return { ...state, loading: action.payload, error: null };
    case 'EVT_ERROR': {
      const r1 = { isLogedIn: false, error: action.payload };
      return { ...state, ...r1, stage: action.stage };
    }
    default:
      return state;
  }
}
