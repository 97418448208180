import React from 'react';
import RespondentAnswer from './RespondentAnswer';

export default function QuestionCard({ question, index }) {
  const { description, title, answer, options } = question;
  return (
    <div className="mb-3 border-top border-dark pt-3">
      <div className="faq-box d-flex align-items-start mb-4">
        <div className="faq-icon mr-3">{index}</div>
        <div className="flex-1">
          <h5 className="font-size-15">{title}</h5>
          <p className="text-muted">{description}</p>
        </div>
      </div>
      <div className="timeline-count overflow-auto">
        <div className="row flex-nowrap justify-content-start md-justify-content-start justify-content-md-center pl-3 pl-md-0">
          {/* d-flex flex-wrap justify-content-center */}
          {options.map((el) => (
            <div key={el._id} className="col-5 col-md-2 timeline-box mt-4">
              <RespondentAnswer {...el} />
            </div>
          ))}
        </div>
      </div>
      <blockquote className="blockquote font-size-16 mb-0">
        <footer className="blockquote-footer mb-2">
          <cite title="">{answer.note ? 'Keterangan dari responden' : 'Tidak ada keterangan'}</cite>
        </footer>
        {answer.note && <p className="mb-0">{answer.note}</p>}
      </blockquote>
    </div>
  );
}
