/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Step2 from '../components/event/Respondent';
import Step3 from '../components/event/Question';
import API from '../config/API';
import callServerV2 from '../helpers/callServerV2';
import { actionClearResponse, actionSetResponse } from '../store/actions/responseAction';
import { actionClearEvent, actionSetStep, actionSetTotStep } from '../store/actions/eventsAction';
// import initScript from '../assets/js/main';
import Thankyou from './Thankyou';
import Scriptjs from '../assets/js/Scriptjs';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';
import Loading from '../components/event/element/Loading';
import { useNavigate } from 'react-router-dom';

const CompLi = (index, className) => {
  return (
    <li key={index} className={`multisteps-form__progress-btn ${className}`}>
      <span>{index}</span>
    </li>
  );
};

const element = CompLi(1, 'js-active current');
const initStep = [element];

export default function Event() {
  const [steps, setSteps] = useState(initStep);
  const [pages, setPages] = useState([]);

  const { slug } = useParams();

  // const history = useNavigate();
  // const { search } = history.location;

  const dispatch = useDispatch();

  const state = useSelector((state) => state.eventReducer);
  const { event, error, submited } = state;
  const { loading: loadingEvent, respondent } = state;

  useEffect(() => {
    dispatch(actionClearEvent());
    loadEvent(); // Load data event
    initConfig();
  }, []);

  useEffect(() => {
    if (event) {
      initSteps();
      initPages();
    }
    return () => {};
  }, [event]);

  useEffect(() => {
    dispatch(actionSetResponse(respondent));
  }, [respondent]);

  useEffect(() => {
    // console.log('error', error);
  }, [error]);

  const initClick = (e) => {
    const scriptjs = new Scriptjs();
    const stepPrevBtnClass = 'js-btn-prev';
    const stepNextBtnClass = 'js-btn-next';
    const stepFormPanelClass = 'multisteps-form__panel';
    const stepFormPanels = document.querySelectorAll('.multisteps-form__panel');

    const $ = window.$;
    const eventTarget = e.target;

    //check if we clicked on `PREV` or NEXT` buttons
    const prevBtn = eventTarget.classList.contains(`${stepPrevBtnClass}`);
    const nextBtn = eventTarget.classList.contains(`${stepNextBtnClass}`);
    if (!(prevBtn || nextBtn)) {
      return;
    }

    //find active panel
    const activePanel = scriptjs.findParent(eventTarget, `${stepFormPanelClass}`);

    let activePanelNum = Array.from(stepFormPanels).indexOf(activePanel);

    //set active step and active panel onclick
    const stepPrevBtn = eventTarget.classList.contains(`${stepPrevBtnClass}`);
    const stepNextBtn = eventTarget.classList.contains(`${stepNextBtnClass}`);
    if (stepPrevBtn) {
      activePanelNum--;
      dispatch(actionSetStep(activePanelNum));
      scriptjs.setActiveStep(activePanelNum);
      scriptjs.setActivePanel(activePanelNum);
    } else if (stepNextBtn) {
      var parent_fieldset = $('.multisteps-form__panel.js-active');
      const form = parent_fieldset.find('.wizard__form');
      form.validate();

      var next_step = true;
      parent_fieldset.find('.required').each(function () {
        next_step = false;
        var form = $('.required');
        form.validate();
        $(this).addClass('custom-select is-invalid');
      });

      if (next_step === true || form.valid() === true) {
        activePanelNum++;
        if (activePanelNum < stepFormPanels.length) {
          dispatch(actionSetStep(activePanelNum));
          $('html, body').animate(
            {
              scrollTop: 200,
            },
            600,
          );
          scriptjs.setActiveStep(activePanelNum);
          scriptjs.setActivePanel(activePanelNum);
        }
      }
    }
  };

  const initConfig = () => {
    const url = API.URL_SURVEY_CONFIG;
    dispatch(
      callServerV2({
        url,
        prefix: 'CFG',
        type: 'SET_CONFIGS',
        stage: 'loadConfigs',
      }),
    );
  };

  // Combine Pages
  const initPages = () => {
    const newP = [<Step2 key={0} initClick={initClick} />];
    event.questions.forEach((el) => {
      newP.push(<Step3 key={el._id} initClick={initClick} item={el} />);
    });
    setPages(newP);
  };

  // Combines All Steps
  const initSteps = () => {
    const res = CompLi(1, 'js-active current');
    const temps = [res];
    const oldLen = temps.length;
    const len = event.questions.length;
    event.questions.forEach((_, i) => {
      let className = '';
      if (i >= len - 1) className = 'last';
      const res = CompLi(i + oldLen + 1, className);
      temps.push(res);
    });
    dispatch(actionClearResponse());
    dispatch(actionSetTotStep(temps.length));
    dispatch(actionSetStep(0));
    setSteps(temps);
  };

  // Load Event Active
  const loadEvent = async () => {
    const url = `${API.URL_SURVEY}/${slug}`;
    dispatch(
      callServerV2({
        url,
        prefix: 'EVT',
        type: 'SET_EVENT',
        stage: 'loadEvents',
      }),
    );
  };

  if (submited) return <Thankyou />;

  return (
    <div className="wrapper">
      <div className="steps-area steps-area-fixed">
        <div className="image-holder">
          <img src={`${process.env.PUBLIC_URL}/assets/img/artist.png`} alt="" />
        </div>
        {event && (
          <div className="steps clearfix">
            <ul className="tablist multisteps-form__progress">{steps}</ul>
          </div>
        )}
      </div>
      <div className="multisteps-form__form">
        <div className="form-area position-relative" style={{ height: '100vh' }}>
          {/* {pages} */}
          {event && pages}
          {loadingEvent && !event && <Loading />}
          {!loadingEvent && !event && <NotFound />}
        </div>
      </div>
    </div>
  );
}
