import React from 'react';

export default function RespondentAnswer({ text, value, isSelected }) {
  const className = isSelected ? 'primary' : 'secondary';
  const classDot = isSelected ? 'dot-outline bg-transparent p-2' : 'bg-secondary';
  return (
    <div className="timeline-spacing mb-1">
      <div className={`item-lable bg-${className} rounded`}>
        <p className="text-center text-white">{value}</p>
      </div>
      <div className="timeline-line active">
        <div className="dot bg-secondary">
          {isSelected && <div className={`dot ${classDot}`}></div>}
        </div>
      </div>
      <div className="vertical-line">
        <div className="wrapper-line bg-light" />
      </div>
      <div className={`bg-${className} p-1 rounded text-center mx-1`}>
        <p className="text-white mt-1 mb-0">{text}</p>
      </div>
    </div>
  );
}
