import React from 'react';

/**
 *
 * @param {{
 * type: 'text' | 'number' | 'email',
 * name: String,
 * value: String,
 * label: String,
 * handleChange: Function,
 * errMsg: String }} props
 * @returns
 */
export default function TextInput(props) {
  const { type, name, value, label, handleChange, errMsg } = props;

  const handleOnChange = (e) => {
    const value = e.target.value;
    handleChange(value, name);
  };

  const defaultProps = {
    type: type || 'text',
    name: name,
    value: value,
    autoComplete: 'off',
    className: `form-control ${errMsg ? 'is-invalid error mb-1' : ''}`,
    placeholder: label,
    noValidate: errMsg ? 'novalidate' : '',
  };
  if (type === 'number') defaultProps.min = 0;

  return (
    <>
      <input onChange={handleOnChange} {...defaultProps} aria-invalid={!!errMsg} />
      {errMsg && (
        <label id={`${name}-error`} className="error" htmlFor={name}>
          {errMsg}
        </label>
      )}
    </>
  );
}
