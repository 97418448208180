export const actionSetEvent = (payload) => {
  return { type: "EVT_SET_EVENT", stage: "setEvent", payload };
};
export const actionSetStep = (payload) => {
  return { type: "EVT_SET_CURR_STEP", payload };
};
export const actionSetTotStep = (payload) => {
  return { type: 'EVT_SET_TOTAL_STEP', payload };
};
export const actionSetSubmited = (payload) => {
  return { type: 'EVT_SET_SUBMITED', payload };
};
export const actionClearEvent = (payload = null) => {
  return { type: 'EVT_CLEAR_EVENT', payload };
};
