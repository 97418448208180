import { axios, defaultHeaders } from "../config/axios";

const init = {
  url: "",
  stage: "",
  method: "get",
  data: null,
  headers: true,
  type: "",
  prefix: "",
  formData: false,
  id: null,
};

/**
 * @param {{
 * url: String,
 * stage: String,
 * method: 'post' | 'get' | 'put' | 'patch' | 'delete',
 * data: Object,
 * headers: true | false,
 * type: String,
 * formData: true | false,
 * id: String
 * prefix: String
 * }} option
 */
function callServerV2(option = init) {
  const mt = ["post", "get", "put", "patch", "delete"];
  if (!mt.includes(option.method)) option.method = "get";
  const payload = { data: {}, headers: {}, url: option.url, method: option.method };

  if (option.data) {
    payload.data = option.data;
  }

  if (option.headers) {
    payload.headers = defaultHeaders(option.formData);
  }

  return async (dispatch) => {
    try {
      dispatch({ type: `${option.prefix}_LOADING`, payload: true });
      const { data } = await axios(payload);
      // console.log("Data From Axios: " + option.stage, data);
      dispatch({
        type: `${option.prefix}_${option.type}`,
        stage: option.stage,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: `${option.prefix}_ERROR`,
        stage: option.stage,
        payload: error,
      });
      console.error(error.response || error);
    } finally {
      dispatch({ type: `${option.prefix}_LOADING`, payload: false });
    }
  };
}

export default callServerV2;
