/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../../config/API';
import { axios, defaultHeaders } from '../../../config/axios';
import errorHandler from '../../../helpers/errorHandler';
import SweatAlert from '../../../helpers/SweatAlert';
import { actionSetSubmited } from '../../../store/actions/eventsAction';

const base = () => true;

export default function ButtonNav(props) {
  const { validate = base } = props;
  const { initClick } = props;
  const [loading, setLoading] = useState(false);

  const { totalSteps, currentStep } = useSelector((state) => state.eventReducer);
  const response = useSelector((state) => state.responseReducer);

  const dispatch = useDispatch();

  const handlePush = async (response) => {
    try {
      setLoading(true);
      await axios.post(API.URL_REPONSE, response, {
        helpers: defaultHeaders(),
      });
      setLoading(false);
      dispatch(actionSetSubmited(true));
    } catch (error) {
      console.error(error);
      const msg = errorHandler(error);
      if (typeof msg === 'string') {
        SweatAlert.warning(msg);
      }
      setLoading(false);
    }
  };

  const handleSubmit = (response) => {
    SweatAlert.confirm('Simpan Semua Jawaban?', 'Klik Yes untuk menyimpan perubahan', () =>
      handlePush(response),
    );
  };

  const handleNav = async (e, nav) => {
    if (loading) return;
    switch (nav) {
      case 'next': {
        if (await validate()) {
          initClick(e);
        } else {
          window.$('html, body').animate(
            {
              scrollTop: 200,
            },
            600,
          );
        }
        break;
      }
      case 'back': {
        if (currentStep > 1) {
          //
        }
        break;
      }
      case 'submit': {
        if (validate()) {
          initClick(e);
          handleSubmit(response);
        } else {
          window.$('html, body').animate(
            {
              scrollTop: 200,
            },
            600,
          );
        }
        break;
      }
      default:
        break;
    }
  };
  return (
    <>
      <div className="actions">
        <ul>
          {currentStep < 1 && (
            <li>
              <Link to="/">
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left" /> BACK
                </span>
              </Link>
            </li>
          )}
          <li
            className={currentStep < 1 ? 'disable' : ''}
            aria-disabled={currentStep < 1 ? true : false}>
            <span className="js-btn-prev" title="BACK" onClick={initClick}>
              <i className="fa fa-arrow-left" /> BACK
            </span>
          </li>
          {currentStep < totalSteps - 1 ? (
            <li>
              <span className="js-btn-next" title="NEXT" onClick={(e) => handleNav(e, 'next')}>
                NEXT
                <i className="fa fa-arrow-right ml-2" />
              </span>
            </li>
          ) : (
            <li className={loading ? 'disable' : ''}>
              <span
                className="js-btn-submit"
                title="SUBMIT"
                onClick={(e) => handleNav(e, 'submit')}>
                SUBMIT
                {loading ? (
                  <i className="fas fa-spinner fa-pulse ml-2"></i>
                ) : (
                  <i className="fa fa-save ml-2" />
                )}
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
