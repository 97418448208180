import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetResponse } from '../../../store/actions/responseAction';

export default function TextArea({ questId, response: value }) {
  const response = useSelector((state) => state.responseReducer);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e.target.value;
    const newRes = { ...response };
    // Check Answer
    const index = newRes.questions.map((el) => el.questionId).indexOf(questId);
    if (index > -1) {
      newRes.questions[index].note = value;
    } else {
      newRes.questions.push({
        questionId: questId,
        note: value,
      });
    }
    dispatch(actionSetResponse(newRes));
  };

  return (
    <div className="comment-box">
      <p>
        <i className="fas fa-comments"></i> Tulis Keterangan Disini
      </p>
      <textarea
        onChange={(e) => handleChange(e)}
        name="full_comments"
        placeholder="Keterangan"
        value={value ? value : ''}></textarea>
    </div>
  );
}
