import Swal from 'sweetalert2';
const blank = () => {};

class SweatAlert {
  static confirm(question, desc, handleConfirm = blank, hanleDismiss = blank, icon = 'question') {
    Swal.fire({
      title: question,
      text: desc,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirm();
      } else if (result.isDismissed) {
        hanleDismiss();
      }
    });
  }

  static warning(msg) {
    Swal.fire('Opss..', msg, 'warning');
  }

  static success(msg, action = blank) {
    Swal.fire('Berhasil', msg, 'success').then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        action();
      }
    });
  }
}

export default SweatAlert;
